import React, { Component } from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label } from 'reactstrap'
import axios from 'axios'

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    insuranceType: [],
    alert: '',
    color: '',
  }

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
    })

  handleSubmit = event => {
    event.preventDefault()
    import('react-facebook-pixel').then(function(ReactPixel) {
      ReactPixel.default.track('Lead')
    })
    axios
      .post(
        'https://b5irt0fm76.execute-api.us-east-1.amazonaws.com/prod/contact',
        {
          ...this.state,
        }
      )
      .then(response => {
        this.setState({
          alert:
            "Thank you for your interest. We'll be getting in contact with you soon",
          color: 'success',
          name: '',
          email: '',
          message: '',
          insuranceType: '',
        })
      })
      .catch(err => {
        this.setState({
          alert:
            'We were not able to send your message. Please call us so that we can help you.',
          color: 'danger',
        })
      })
  }

  render() {
    return (
      <Form className="pl-md-3" onSubmit={this.handleSubmit}>
        {this.state.alert ? (
          <Alert color={this.state.color}>{this.state.alert}</Alert>
        ) : null}
        <FormGroup row>
          <Label for="inputName" sm={3} className="text-white">
            Name
          </Label>
          <Col sm={9}>
            <Input
              aria-label="Your Name"
              type="text"
              name="name"
              id="inputName"
              required
              placeholder="Full Name"
              onChange={this.handleChange}
              value={this.state.name}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="inputEmail" sm={3} className="text-white">
            Email
          </Label>
          <Col sm={9}>
            <Input
              aria-label="Your Email"
              type="email"
              name="email"
              id="inputEmail"
              required
              placeholder="email@domain.com"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="insuranceType" sm={3} className="text-white">
            Type of Insurance
          </Label>
          <Col sm={9}>
            <Input
              aria-label="Insurance Type"
              type="Text"
              name="insuranceType"
              id="insuranceType"
              onChange={this.handleChange}
              value={this.state.insuranceType}
              placeholder="Car, Home and/or Business?"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="inputMessage" sm={3} className="text-white">
            Message
          </Label>
          <Col sm={9}>
            <Input
              aria-label="Message"
              type="textarea"
              name="message"
              id="inputMessage"
              rows="4"
              placeholder="Anything else you might like to tell us?"
              onChange={this.handleChange}
              value={this.state.message}
            />
          </Col>
        </FormGroup>
        <FormGroup check row>
          <Col xs={12} className="d-flex justify-content-center">
            <Button color="primary" size="lg" aria-label="Submit Form">
              Submit
            </Button>
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

export default ContactForm
