import React, { Component } from 'react'
import { Col } from 'reactstrap'

class WhyUsReason extends Component {
  state = {
    hovered: false,
  }

  render() {
    return (
      <Col xs="12" md="4">
        <div
          className="why-us-wrapper p-1"
          onMouseEnter={() => {
            this.setState({ hovered: true })
          }}
          onMouseLeave={() => {
            this.setState({ hovered: false })
          }}
        >
          <div className="why-us-image">
            <img
              src={this.props.image}
              alt={this.props.title}
              className={`img img-fluid ${
                this.state.hovered ? 'hovered' : null
              }`}
            />
          </div>
          <div className="why-us-title w-100">
            <h4
              className={`text-center text-white p-3 ml-1 ${
                this.state.hovered ? 'hovered' : null
              }`}
            >
              {this.props.title}
            </h4>
          </div>
        </div>
      </Col>
    )
  }
}

export default WhyUsReason
