import React from 'react'
import { Col, Container, Row } from 'reactstrap'

export default () => (
  <section id="CtaGetQuote" className="bg-info py-5 px-2">
    <Container>
      <Row className="d-flex align-items-center">
        <Col xs="12" md="9" className="mb-2">
          <h3 className="text-white mb-0">
            Get a Free Quote in just a few minutes. No hidden Fees.
          </h3>
        </Col>
        <Col xs="12" md="3" className="mb-2">
          <a href="tel:+18777897411" className="btn btn-lg btn-secondary">
            CHECK YOUR RATE
          </a>
        </Col>
      </Row>
    </Container>
  </section>
)
