import { Component } from 'react'

class TypeSwitcher extends Component {
  state = {
    type: 'Car',
    currentIndex: 0,
    available: ['Car', 'Home', 'Business'],
  }

  switchService = () => {
    if (this.state.currentIndex < this.state.available.length - 1) {
      this.setState(prevState => ({
        currentIndex: prevState.currentIndex + 1,
        type: prevState.available[prevState.currentIndex + 1],
      }))
    } else {
      this.setState(prevState => ({
        currentIndex: 0,
        type: prevState.available[0],
      }))
    }
  }

  componentDidMount = () => {
    this.intervalId = setInterval(this.switchService, 3000)
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalId)
  }

  render() {
    return this.state.type
  }
}

export default TypeSwitcher
