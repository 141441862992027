import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import servicesData from '../data/services.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCar, faBriefcase } from '@fortawesome/free-solid-svg-icons'

const icons = {
  car: faCar,
  home: faHome,
  business: faBriefcase,
}

function renderService(service) {
  return (
    <Col
      key={service.key}
      xs="12"
      lg="4"
      className="text-center p-5"
      style={{ backgroundColor: service.backgroundColor }}
    >
      <FontAwesomeIcon
        icon={icons[service.icon]}
        size="4x"
        color="white"
        className="mb-3"
      />
      <h2 className="text-white mb-4">{service.title}</h2>
      <p className="lead text-gray text-justify">{service.description}</p>
    </Col>
  )
}

export default () => (
  <section id="OurServices">
    <Container fluid>
      <Row>{servicesData.services.map(renderService)}</Row>
    </Container>
  </section>
)
