import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'

export default () => (
  <section id="CtaMessenger" style={{ position: 'relative' }}>
    <div className="overlay-for-section" />
    <Container className="container-for-overlay py-5">
      <h4 className="text-center text-white py-3">Get free consultations</h4>
      <Row className="d-flex align-items-center">
        <Col xs="12" lg="9" className="mb-3">
          <h2 className="text-center text-white">
            Checking your Insurance options does not affect your credit score
            and it only takes a few minutes to start saving money.
          </h2>
        </Col>
        <Col xs="12" lg="3" className="text-center mb-3">
          <a
            href="https://m.me/youfirstinsurance"
            alt="Have a chat via Facebook Messenger"
            className="btn btn-lg btn-outline-primary"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            <FontAwesomeIcon
              icon={faFacebookMessenger}
              size="2x"
              style={{ verticalAlign: 'middle' }}
            />{' '}
            Let's Chat
          </a>
        </Col>
      </Row>
    </Container>
  </section>
)
