import React, { Component } from 'react'
import { Alert, Button, Container, Form, FormGroup, Input } from 'reactstrap'
import axios from 'axios'

class Showcase extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    alert: '',
    color: '',
  }

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
    })

  handleSubmit = event => {
    event.preventDefault()
    import('react-facebook-pixel').then(function(ReactPixel) {
      ReactPixel.default.track('Lead')
    })
    axios
      .post(
        'https://b5irt0fm76.execute-api.us-east-1.amazonaws.com/prod/contact',
        {
          ...this.state,
        }
      )
      .then(response => {
        this.setState({
          alert:
            "Thank you for your interest. We'll be getting in contact with you soon",
          color: 'success',
          name: '',
          email: '',
          message: '',
        })
      })
      .catch(err => {
        this.setState({
          alert:
            'We were not able to send your message. Please call us so that we can help you.',
          color: 'danger',
        })
      })
  }

  render() {
    return (
      <section
        id="Showcase"
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '750px', position: 'relative' }}
      >
        <div className="overlay" />
        <Container style={{ zIndex: 3 }}>
          <h2 className="text-white mb-4">
            Save up to $720/year on Car Insurance!
          </h2>
          <Form onSubmit={this.handleSubmit}>
            {this.state.alert ? (
              <Alert color={this.state.color}>{this.state.alert}</Alert>
            ) : null}
            <FormGroup>
              <Input
                type="text"
                name="name"
                id="name"
                aria-label="Your Name"
                placeholder="Name"
                required
                onChange={this.handleChange}
                value={this.state.name}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                id="email"
                aria-label="Your Email"
                placeholder="Email"
                required
                onChange={this.handleChange}
                value={this.state.email}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="message"
                id="message"
                aria-label="Your Message"
                placeholder="Message or Phone Number"
                onChange={this.handleChange}
                value={this.state.message}
              />
            </FormGroup>
            <Button color="primary" size="lg" className="btn-block">
              I want to start saving!
            </Button>
          </Form>
        </Container>
      </section>
    )
  }
}

export default Showcase
