import React, { Component } from 'react'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/ContactUs'
import CtaGetQuote from '../components/CtaGetQuote'
import CtaMessenger from '../components/CtaMessenger'
import Footer from '../components/Footer'
import InsuranceProcess from '../components/InsuranceProcess'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Services from '../components/Services'
import Showcase from '../components/Showcase'
import WhyUs from '../components/WhyUs'
import '../styles/index.scss'

class IndexPage extends Component {
  componentDidMount() {
    import('react-facebook-pixel').then(function(ReactPixel) {
      ReactPixel.default.init('383226998912791')
      ReactPixel.default.pageView()
    })

    import('react-ga').then(function(ReactGA) {
      ReactGA.initialize('UA-611766-12')
      ReactGA.pageview(window.location.pathname + window.location.search)
    })
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Home"
          keywords={[
            `car insurance`,
            `home insurance`,
            `business insurance`,
            `california insurance`,
            `cheap insurance`,
            `best coverage insurance`,
            `best rate insurance`,
            `anaheim car insurance`,
            `santa ana car insurance`,
          ]}
        />
        <Showcase />
        <AboutUs />
        <Services />
        <CtaGetQuote />
        <InsuranceProcess />
        <CtaMessenger />
        <WhyUs />
        <ContactUs />
        <Footer />
      </Layout>
    )
  }
}

export default IndexPage
