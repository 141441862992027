import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faChartLine,
  faHandshake,
  faPiggyBank,
} from '@fortawesome/free-solid-svg-icons'
import processData from '../data/processSteps.json'

const icons = {
  call: faPhone,
  lookup: faChartLine,
  handshake: faHandshake,
  savings: faPiggyBank,
}

function renderProcessSteps(step) {
  return (
    <Col
      xs="12"
      md="6"
      lg="3"
      className="d-flex flex-column mb-3"
      key={step.key}
    >
      <h2 className="align-self-center text-white rounded-circle bg-success py-3 px-5 display-4 step-number">
        <strong>{step.key}</strong>
      </h2>
      <div className="d-flex flex-column step-description rounded flex-fill">
        <h3 className="mt-1 text-secondary">
          <strong>{step.title}</strong>
        </h3>
        <p className="mt-4">{step.description}</p>
        <FontAwesomeIcon
          icon={icons[step.icon]}
          size="4x"
          className="mt-auto align-self-center text-gray"
        />
      </div>
    </Col>
  )
}

export default () => (
  <section id="InsuranceProcess" className="py-5">
    <Container fluid>
      <h2 className="text-secondary text-center pt-4">
        <strong>Simple Insurance Process</strong>
      </h2>
      <p className="text-muted text-center py-4">
        With our refined process and capable Customer Support you could start
        saving in less than 1 day.
      </p>
      <Row className="text-center">
        {processData.steps.map(renderProcessSteps)}
      </Row>
    </Container>
  </section>
)

/*
<Col xs="12" md="3">
          <h2 className="d-inline text-white rounded-circle bg-success py-3 px-5 display-4 step-number">
            2
          </h2>
          <div className="step-description">
            <h3>Best Rate Lookup</h3>
            <p className="mt-4">
              We enter your information in our Platform, and carefully review
              the available options to provide you with the best Coverage and
              Rate.
            </p>
          </div>
        </Col>
        <Col xs="12" md="3">
          <h2 className="d-inline text-white rounded-circle bg-success py-3 px-5 display-4 step-number">
            3
          </h2>
          <div className="step-description">
            <h3>Approved Insurance</h3>
            <p className="mt-4">
              We present you the best options, explain the differences and
              answer any questions that might arise. Once you decide on one we
              move forward on activating the Insurance.
            </p>
          </div>
        </Col>
        <Col xs="12" md="3">
          <h2 className="d-inline text-white rounded-circle bg-success py-3 px-5 display-4 step-number">
            4
          </h2>
          <div className="step-description">
            <h3>Savings Increased</h3>
            <p className="mt-4">
              You start saving monthly and yearly on your perfect Insurance. We
              send you the policies and make sure that the Insurance Company
              activates them.
            </p>
          </div>
        </Col>
        */
