import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import TypeSwitcher from './TypeSwitcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'

export default () => (
  <section id="AboutUs">
    <Container className="py-5">
      <Row>
        <Col xs="12" lg="6" className="mb-3">
          <h1 className="text-secondary text-center">
            <strong>
              We are <span className="text-primary">YouFirst Insurance</span>
            </strong>
          </h1>
          <div className="py-4">
            <p>
              An Insurance Agency located in the city of Anaheim. We offer the
              best customer service in the area and competitive prices for your
              <strong>Auto-Home-Business Insurance</strong> needs.
            </p>
            <p>
              We believe in a different approach of doing business, one that is
              driven by good and not greed. We will truly put your insurance
              needs first in order to earn your business for life!
            </p>
            <p>
              Call us Today and start saving on your {<TypeSwitcher />}{' '}
              Insurance!
            </p>
          </div>
          <a
            href="tel:+18777897411"
            className="btn btn-lg btn-primary px-4 mb-2"
          >
            <FontAwesomeIcon
              icon={faMobileAlt}
              size="2x"
              style={{ verticalAlign: 'middle', marginRight: '1rem' }}
            />
            <span className="h4">Call Us</span>
          </a>
        </Col>
        <Col xs="12" lg="6" className="mb-3">
          <div
            className="d-flex justify-content-center align-items-center py-5"
            style={{ boxShadow: '0px 0px 20px #f6f6f6f6' }}
          >
            <ul className="list-unstyled">
              <li className="mb-3 h5">
                <strong>
                  <span className="text-primary">No</span> Initiation Fee
                </strong>
              </li>
              <li className="mb-3 h5">
                <strong>Top Coverage for Less</strong>
              </li>
              <li className="mb-3 h5">
                <strong>Satisfaction Guaranteed</strong>
              </li>
              <li className="mb-3 h5">
                <strong>No Hidden Fees or Tricks</strong>
              </li>
              <li className="mb-3 h5">
                <strong>You can cancel at anytime</strong>
              </li>
              <li className="h5">
                <strong>
                  <span className="text-primary">Free</span> Quotes
                </strong>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)
