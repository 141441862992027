import React, { Component } from 'react'
import { Container, Row } from 'reactstrap'
import WhyUsReason from './WhyUsReason'
import whyUsData from '../data/whyUsReasons.json'

function renderReason(item) {
  return <WhyUsReason {...item} />
}

class WhyUs extends Component {
  render() {
    return (
      <section id="WhyUs">
        <Container className="py-5">
          <h2 className="text-secondary text-center pb-3">
            <strong>Why Choose Our Insurance Agency</strong>
          </h2>
          <p className="text-muted text-center pb-3">
            We offer you the Best Rates, Best Coverage, Incredible Customer Care
            and also:
          </p>
          <Row>{whyUsData.reasons.map(renderReason)}</Row>
        </Container>
      </section>
    )
  }
}

export default WhyUs
