import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faFacebookMessenger,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  faMapMarkerAlt,
  faMobileAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import contactInfo from '../data/contactInfo.json'
import ContactForm from './ContactForm'

const icons = {
  map: faMapMarkerAlt,
  phone: faMobileAlt,
  email: faEnvelope,
}

function renderContactInfo(item) {
  return (
    <Row className="d-flex align-items-center mb-4" key={item.key}>
      <Col xs="2" className="text-center">
        <div className="text-center">
          <a
            href={item.link}
            alt={item.alt}
            aria-label={item.alt}
            rel="nofollow noreferrer noopener"
            target="_blank"
            className="text-white"
          >
            <FontAwesomeIcon
              icon={icons[item.icon]}
              size="3x"
              style={{ verticalAlign: 'middle' }}
              className="w-100"
            />
          </a>
        </div>
      </Col>
      <Col xs="10">
        <div>
          <a
            href={item.link}
            alt={item.alt}
            aria-label={item.alt}
            rel="nofollow noreferrer noopener"
            target="_blank"
            className="text-white"
          >
            {item.content}
          </a>
        </div>
      </Col>
    </Row>
  )
}

export default () => (
  <section id="ContactUs" style={{ position: 'relative' }}>
    <div className="overlay-for-section" />
    <Container className="container-for-overlay pb-5">
      <Row>
        <Col xs="12" md="5" className="rounded bg-primary py-3">
          <h3 className="text-dark text-center py-4">
            <strong>SOCIAL MEDIA</strong>
          </h3>
          <div className="social-media-accounts d-flex justify-content-center mb-4">
            <a
              href="https://facebook.com/youfirstinsurance"
              alt="YouFirst Insurance on Facebook"
              aria-label="YouFirst Insurance on Facebook"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                size="3x"
                className="text-white mr-4"
              />
            </a>
            <a
              href="https://m.me/youfirstinsurance"
              alt="YouFirst Insurance on Messenger"
              aria-label="YouFist Insurance Messenger"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookMessenger}
                size="3x"
                className="text-white mr-4"
              />
            </a>
            <a
              href="https://instagram.com/youfirstinsurance"
              alt="YouFirst Insurance on Instagram"
              aria-label="YouFirst Insurance on Instagram"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="3x"
                className="text-white mr-4"
              />
            </a>
          </div>
          <Container>
            <h3 className="text-dark text-center py-4">
              <strong>CONTACT DETAILS</strong>
            </h3>
            {contactInfo.points.map(renderContactInfo)}
          </Container>
        </Col>
        <Col xs="12" md="7">
          <h2 className="text-white text-center py-4">Contact Form</h2>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </section>
)
