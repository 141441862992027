import React from 'react'

export default () => (
  <footer className="py-1 bg-dark">
    <p className="lead text-white text-center mb-0">
      &copy; YouFirst Insurance leaders in Business, Home and Car Insurance.
      Lic#OK11696
    </p>
  </footer>
)
